<template>
  <LayoutFilter
    :onFilter="onFilter"
    :onReset="resetForm"
    :toggleCollapseEnable="false"
    :onExport="onExport"
    :defaultCollapse="false"
  >
    <el-form :model="form" label-height="300px"  label-width="80px" class="filter-from" ref="resetFilterForm">
      <div class="filter-item">
        <el-form-item label="姓名：" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="会内职务：" prop="positionIds">
          <el-cascader v-model="form.positionIds" :options="parentPositionList" :props="props" placeholder="请选择"></el-cascader>
        </el-form-item>
        <el-form-item label="手机号码：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="认证状态：" prop="status">
          <el-select v-model="form.status" placeholder="请选择">
             <el-option v-for="(item, index) in statusArr" :key="index" :label="item.label" :value="item.value
             "></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="认证时间：" 
          start-prop="start_time"
          :start-time.sync="form.start_time" 
          end-prop="end_time"
          :end-time.sync="form.end_time" 
        ></DatePeriodPicker>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { positionLevel } from "../../api/member/member-list";
import { onExportMembers } from '../../api/school-student-list'
export default {
  props: {
    // 筛选
    filterChange: {
      type: Function,
      required: true
    },
    // 导出
    exportData: {
      type: Object,
      required: true
    },
    page: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      parentPositionList: [],
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position'
      },
      // form
      form: {
        name: '',
        phone: '',
        start_time: '',
        end_time: '',
        positionIds: [],
        status: -1
      },
      // 认证状态
      statusArr: [
        { label: '全部', value: -1 },
        { label: '未认证', value: 0 },
        { label: '已认证', value: 1 },
      ]
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["resetFilterForm"].resetFields();
      return this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form))
    },
    // 导出
    onExport() {
      const postData = {...this.exportData, page: this.page}
      return onExportMembers(postData)
        .then()
        .catch((err) => {console.log(err)});
    },

  },
  computed: {
    position_id: {
      get() {
        return this.form.position_id == -1 ? "" : this.form.position_id;
      },
      set(val) {
        this.form.position_id = val
      }
    }
  },
  created () {
    positionLevel({ is_list_tree: 0, is_show_all: 1 })
    .then((res) => {
      this.parentPositionList = res.data
    })
    .catch(err => console.log(err))
  },
  watch: {
    positionIds(val) {
      this.position_id = val[val.length -1] ? val[val.length - 1] : -1
    }
  },
  components: {
    LayoutFilter,
    DatePeriodPicker
  },
};
</script>

<style lang="scss" scoped>

</style>