
<template>
  <el-dialog title="添加校友" :visible.sync="openDialog" width="582px" height="432px"
             class="dialog-vertical" close-on-press-escape close-on-click-modal>
    <div class="btn">
        <el-button @click="addMember">
            <p>添加已认证校友</p>
            <span>（需填写手机号码、班级信息）</span>
        </el-button>
        <el-button @click="add">
            <p>添加未认证校友</p>
            <span>（仅填写班级信息）</span>
        </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
    filterForm: Object,
    unVerifiedRouteName: String,
  },
  data() {
    return {
    };
  },
  methods: {
      add() {
        const data = {};
        data.id = 0;
        // data.type = this.filterForm.tab;
        data.status = "";
        this.$router.push({
            name: this.unVerifiedRouteName || "AddUncertifiedStudent",
            params: { data: this.$enCode(data) },
        });
      },
    // 添加会员
    addMember() {
      const data = {};
      data.id = 0;
      data.type = this.filterForm?.tab || 'normal';
      data.status = "";
      this.$router.push({
        name: "AddMember",
        params: { data: this.$enCode(data) },
      });
    },
  },
  watch: {
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
    .btn {
        display: flex;
        justify-content: center;
        .el-button {
            width: 238px;
            height: 292px;
        }
    }
}
</style>
