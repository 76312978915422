<template>
  <div class="member">
    <listLayout ref="ListLayout" :thead="thead" :on-fetch="getList" :filter-form="filterForm">
      <template #top>
        <el-button style="margin-bottom: 10px" size="small" type="primary" @click="isShowAdd = true">{{$route.name === 'SchoolStudentList' ? '添加校友': '添加班级成员'}}</el-button>
      </template>
      <template #filter>
          <ListFilter
            :page="pageData.current_page"
            :filterChange="onFilter"
            :exportData="filterForm"></ListFilter>
      </template>
      <template slot="item_statusText" slot-scope="{row}">
        <list-status-text :text="row.status_text" :type="row.status_text === '已认证'?'success':'danger'" />
      </template>
      <template v-slot:operation="scope">
        <el-button type="text" size="small" @click="pro(scope.row)">查看</el-button>
      </template>
    </listLayout>
    <!-- 添加校友弹窗 -->
    <addStudent v-model="isShowAdd" :updateList="reflashList"></addStudent>
  </div>
</template>

<script>
import ListLayout from '@/base/layout/ListLayout'
import ListFilter from '../../components/class-members/ListFilter';
import addStudent from '../../components/class-members/addStudent';
import { ClassMembers } from '../../api/school-student-list';
import ListStatusText from "@/base/components/List/ListStatusText";
export default {
  components: { ListLayout, ListFilter, addStudent, ListStatusText },
  data() {
    return {
      thead: [
          { label: 'ID', prop: 'id', minWidth: 150},
          { label: '头像', prop: 'avatar', minWidth: 150},
          { label: '姓名', prop: 'name', minWidth: 150},
          { label: '会内职务', prop: 'title_name', minWidth: 150},
          { label: '手机号码', prop: 'phone', minWidth: 150},
          { label: '认证状态', type: 'slot', prop: 'statusText', minWidth: 150},
          { label: '认证时间', prop: 'auth_time', sortable: true, sort_orders: ['ascending', 'descending'], minWidth: 200},
          { label: '操作', type: 'operation', minWidth: 50},
      ],
      // 筛选对象
        // 筛选对象
        filterForm: {
            class_id: this.$route.params.id || '',
            page: 1,
            page_size: 15,
            name: '',
            phone: '',
            start_time: '',
            end_time: '',
            positionIds: [],
            export_ids: [],
        },
      pageData: {
        current_page: 1
      },
      isShowAdd: false
    };
  },
  methods: {
    getList(data) {
      return ClassMembers(data)
    },
    reflashList() {
      this.$refs.ListLayout.getList();
    },
    // 查看
    pro(row) {
      console.log(row)
      const data = {};
      data.id = row.id;
      data.class_id = row.class_id
      data.type = 'formal'
      console.log(row)
      if (row.status === 1) {
        this.$router.push({
          name: "EditMember",
          params: { data: this.$enCode(data) },
        });
      } else if (row.status === 0) {
        this.$router.push({
          name: "EditUncertifiedStudent",
          params: { id: row.student_id },
        });
      }
    },
    // 筛选回调
    onFilter(filterObj) {
      Object.assign(this.filterForm, filterObj, { page: 1 });
      this.$refs.ListLayout.getList();
    },
  },
  computed: {
  },
};
</script>

<style lang="scss" scoped>

</style>