import api from '@/base/utils/request';
import download from "@/base/utils/download";

// 获取班级成员列表
export const ClassMembers = (data) => {
  return api({
    url: '/admin/school/student/index',
    method: 'post',
    data
  })
}

//导出班级成员列表
export const onExportMembers = (data) => {
  return download({
    url: '/admin/school/student/export',
    method: 'post',
    data,
    download: true
  })
}

// 添加未认证校友
export const addNoAuthStudent = (data) => {
  return api({
    url: '/admin/school/student/addNoAuthStudent',
    method: 'post',
    data,
  })
}